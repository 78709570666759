import Home from "components/Home";
import Seo from "components/Shared/seo";
import { fetchAPI, fetchNews } from "lib/api";
import { isEmpty } from "lib/utils";
import React from "react";
import Layout from "../components/Layout";

const Index = ({ homepage, global, userAgent }) => {
  let homepageCustom = homepage;
  homepageCustom.news.mostPopular = global.mostPopular;
  return (
    <Layout>
      <Seo seo={homepage.seo} />
      <Home homepage={homepageCustom} userAgent={userAgent} />
    </Layout>
  );
};

export async function getServerSideProps(context) {
  try {
    const userAgent = context.req.headers["user-agent"];
    const page = context?.query?.page - 1 || 0;
    const [homepage, news] = await Promise.all([
      fetchAPI("/homepage"),
      fetchNews(null, "created_at:desc", page),
    ]);
    // Assign news data to homepage
    if (isEmpty(homepage) || isEmpty(news)) {
      return {
        redirect: {
          destination: "/500",
          permanent: true,
        },
      };
    }
    homepage.news = news;
    return {
      props: { homepage, userAgent },
    };
  } catch (err) {
    return {
      redirect: {
        destination: "/500",
        permanent: false,
      },
    };
  }
}

export default Index;
