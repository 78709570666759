import useCarouselRef from "hooks/useCarouselRef";
// import useWindowSize from "hooks/useWindowSize";
import { getStrapiMedia } from "lib/media";
import { formatTime } from "lib/utils";
import { useLanguageContext } from "locale/Translate";
import Link from "next/link";
import { SliderItem } from "./Category4Items";
import { getMobileDetect } from "../Shared/getMobileDetect";
// import Image from "components/Shared/image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import dynamic from "next/dynamic";
// import SliderSlick from "react-slick";
const SliderSlick = dynamic(() => import("react-slick"), {
  ssr: true,
});
var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: false,
  fade: true,
  cssEase: "linear",
  accessibility: true,
  arrows: false,
  // onLazyLoad: () => {
  //   return <>loading</>;
  // },
};
const TopNews = ({ topNews, userAgent }) => {
  const currentDevice = getMobileDetect(userAgent);
  // const size = useWindowSize();
  const { locale } = useLanguageContext();
  const firstData = topNews?.articles[0];
  const [, ...restData] = topNews?.articles?.slice(0, 5);
  const ref = useCarouselRef({
    loop: true,
    margin: 0,
    dots: false,
    nav: true,
    navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 3,
      },
      1500: {
        items: 4,
      },
    },
  });
  const SliderWrapper = ({ children }) =>
    currentDevice.isMobile() ? (
      <SliderSlick
        {...settings}
        className="owl-carousel owl-theme js"
        id="sliderX"
      >
        {children}
      </SliderSlick>
    ) : (
      <>{children}</>
    );

  return (
    <div
      className={
        "container-fluid paddding " + (currentDevice.isMobile() ? "" : "mb-5")
      }
    >
      <div className="row mx-0">
        {!currentDevice.isMobile() && (
          <div
            className="col-md-6 col-md-6 col-sm-12 paddding"
            // data-animate-effect="fadeIn"
          >
            {
              <Link href={`/${firstData?.slug}`}>
                <div
                  className="fh5co_suceefh5co_height"
                  // style={{
                  //   background: `url(${getStrapiMedia(
                  //     firstData?.image,
                  //     "medium"
                  //   )})`,
                  //   minWidth: "100%",
                  //   height: "550px",
                  //   backgroundSize: "cover",
                  //   backgroundPosition: "center",
                  // }}
                >
                  <img
                    src={getStrapiMedia(firstData?.image, "medium")}
                    loading="eager"
                    alt="img"
                  />
                  <div className="fh5co_suceefh5co_height_position_absolute" />
                  <div className="fh5co_suceefh5co_height_position_absolute_font">
                    <div className>
                      <div className="color_fff">
                        <i className="fa fa-clock-o" />
                        &nbsp;&nbsp;
                        {formatTime(
                          firstData?.created_at || firstData?.published_at,
                          locale
                        )}
                      </div>
                    </div>
                    <div className>
                      <div className="fh5co_good_font">
                        {firstData?.title?.substring(0, 160)}{" "}
                        {firstData?.title?.length > 160 ? "..." : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            }
          </div>
        )}
        <div className={`col-md-${currentDevice.isDesktop() ? "6" : "12"}`}>
          <div className="row">
            <SliderWrapper>
              {(currentDevice.isMobile() ? topNews?.articles : restData)?.map(
                (n, index) => {
                  return currentDevice.isMobile() ? (
                    <SliderItem key={index} article={n} />
                  ) : (
                    <div
                      key={index}
                      className="col-md-6 col-sm-6 paddding"
                      // data-animate-effect="fadeIn"
                    >
                      <Link href={`/${n?.slug}`}>
                        <div className="fh5co_suceefh5co_height_2">
                          <img
                            src={getStrapiMedia(n?.image, "small")}
                            loading="eager"
                            alt="img"
                          />
                          <div className="fh5co_suceefh5co_height_position_absolute" />
                          <div className="fh5co_suceefh5co_height_position_absolute_font_2">
                            <div className>
                              <span className="color_fff">
                                <i className="fa fa-clock-o" />
                                &nbsp;&nbsp;
                                {formatTime(
                                  n?.created_at || n?.published_at,
                                  locale
                                )}
                              </span>
                            </div>
                            <div className>
                              <div className="fh5co_good_font_2">
                                {n?.title?.substring(0, 160)}{" "}
                                {n?.title?.length > 160 ? "..." : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                }
              )}
            </SliderWrapper>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNews;
