import { useEffect, useRef } from "react";

const useCarouselRef = (config) => {
  const ref = useRef();
  useEffect(() => {
    if (!window.$) {
      return;
    }

    const newConfig = {
      ...config,
      // lazy: true,
    };

    const node = ref.current;

    window.$(node).owlCarousel(newConfig);

    return () => {
      if (!window.$) {
        return;
      }
      window.$(node).owlCarousel("destroy");
    };
  }, [config]);
  return ref;
};

export default useCarouselRef;
