import useCarouselRef from "hooks/useCarouselRef";
import { getStrapiMedia } from "lib/media";
import { formatTime } from "lib/utils";
import { useState } from "react";

const SliderName = () => (
  <div>
    <div className="fh5co_heading fh5co_heading_border_bottom py-2 mb-4">
      Videos
    </div>
  </div>
);

const SliderItem = ({ index, url, image, title, date }) => {
  const [enableVideo, setEnableVideo] = useState(false);

  return (
    <div className="item" style={{ height: "271px" }}>
      <div className="fh5co_hover_news_img">
        <div className="fh5co_hover_news_img_video_tag_position_relative">
          <div className="fh5co_news_img">
            {enableVideo && (
              <iframe
                id={`video_category_${index}`}
                width="100%"
                height="200"
                title={`video${index}`}
                src={`${url}?rel=0&amp;showinfo=0&autoplay=1`}
              ></iframe>
            )}
          </div>
          {!enableVideo && (
            <>
              <div
                className={`fh5co_hover_news_img_video_tag_position_absolute`}
              >
                <img loading="lazy" src={image} alt={image} />
              </div>

              <div
                className={`fh5co_hover_news_img_video_tag_position_absolute_1`}
                onClick={(e) => setEnableVideo(true)}
              >
                <div className="fh5co_hover_news_img_video_tag_position_absolute_1_play_button_1">
                  <div className="fh5co_hover_news_img_video_tag_position_absolute_1_play_button">
                    <span>
                      <i className="fa fa-play"></i>
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="pt-2">
          <a href="#" className="d-block fh5co_small_post_heading ">
            <span className="truncate_text_2">{title}</span>
          </a>
          <div className="c_g">
            <i className="fa fa-clock-o"></i> {date}
          </div>
        </div>
      </div>
    </div>
  );
};

export const CategoryNewsVideo = ({ videos }) => {
  const listVideos = videos?.videos || [];
  const ref = useCarouselRef({
    loop: false,
    margin: 10,
    dots: false,
    nav: true,
    navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  });

  return (
    <div className="container-fluid pt-3">
      <div
        className="container animate-box container-sm"
        data-animate-effect="fadeIn"
      >
        <SliderName />
        <div ref={ref} className="owl-carousel owl-theme js" id="sliderX">
          {listVideos.map((x, index) => {
            const image = getStrapiMedia(x.image);
            const url = x?.url;
            const title = x?.title;
            const date = formatTime(x?.published_at);
            return (
              <SliderItem
                key={x.id}
                index={index}
                image={image}
                url={url}
                title={title}
                date={date}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CategoryNewsVideo;
