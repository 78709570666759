import News from "components/News";
import { BannerRectangleHorizontal } from "components/Shared/Banner";
import { useGlobalContext } from "pages/_app";
import Category3Items from "./Category3Items";
import Category4Items from "./Category4Items";
import CategoryNewsVideo from "./CategoryNewsVideo";
import TopNews from "./TopNews";

const Home = ({ homepage, userAgent }) => {
  // console.log(`homepage.news`, homepage.news);
  const { banner_home1140x240 } = useGlobalContext();
  return (
    <>
      {homepage?.topNews && (
        <TopNews topNews={homepage?.topNews} userAgent={userAgent} />
      )}

      {banner_home1140x240 && (
        <BannerRectangleHorizontal
          banner={banner_home1140x240}
          userAgent={userAgent}
        />
      )}

      {homepage.trending && (
        <Category4Items articles={homepage.trending.articles} />
      )}

      {homepage.customNews && homepage.customNews.length ? (
        homepage.customNews.map((newsBlock) => {
          return <Category3Items key={newsBlock?.id} news={newsBlock} />;
        })
      ) : (
        <></>
      )}

      {homepage.videos && <CategoryNewsVideo videos={homepage.videos} />}
      <News news={homepage.news} showTags={false} />
    </>
  );
};

export default Home;
