import Image from "components/Shared/image";
import useCarouselRef from "hooks/useCarouselRef";
import { formatTime, toSlug } from "lib/utils";
import Link from "next/link";

const SliderName = ({ titleName }) => (
  <div style={{ display: "flex", justifyContent: "space-between" }}>
    <div className="fh5co_heading fh5co_heading_border_bottom py-2 mb-4">
      {titleName}
    </div>
    <div>
      <Link href={`/category/home/${toSlug(titleName)}`}>Xem thêm</Link>
    </div>
  </div>
);

const SliderItem = ({ title, date, image, slug }) => (
  <div className="item" style={{ height: "271px" }}>
    <div className="fh5co_hover_news_img">
      <Link href={"/" + slug}>
        <a>
          <div className="fh5co_news_img">
            {/* <img src={image} alt={image} /> */}
            <Image image={image} />
          </div>
        </a>
      </Link>
      <div>
        <Link href={"/" + slug}>
          <a className="d-block fh5co_small_post_heading">
            <span className="truncate_text_2">{title}</span>
          </a>
        </Link>

        <div className="c_g">
          <i className="fa fa-clock-o"></i> {date}
        </div>
      </div>
    </div>
  </div>
);

export const Category3Items = ({ news }) => {
  const listNews = news?.articles || [];
  const ref = useCarouselRef({
    loop: false,
    margin: 10,
    dots: false,
    nav: true,
    navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  });

  return (
    <div className="container-fluid pt-3">
      <div
        className="container animate-box container-sm"
        data-animate-effect="fadeIn"
        style={{
          minHeight: "271px",
        }}
      >
        <SliderName titleName={news?.title} />
        <div ref={ref} className="owl-carousel owl-theme js" id="sliderX">
          {listNews.map((x, index) => {
            const image = x.image;
            const title = x?.title;
            const date = formatTime(x?.created_at || x?.published_at);
            const slug = x.slug;
            return (
              <SliderItem
                key={x.id}
                index={index}
                image={image}
                title={title}
                date={date}
                slug={slug}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Category3Items;
