import { getStrapiMedia } from "lib/media";
import ImageNext from "next/image";

const Image = ({
  image,
  style,
  className,
  format = "small",
  objectFit = "cover",
}) => {
  let imageUrl = getStrapiMedia(image, format);

  if (!image) {
    return <></>;
  }

  return (
    <ImageNext
      loader={({ src, width, quality }) => {
        if (width === 640) {
          return getStrapiMedia(image, "small");
        }
        return imageUrl;
      }}
      src={imageUrl}
      alt={image.alternativeText || image.name}
      style={style}
      className={className}
      layout="fill"
      loading="lazy"
      objectFit={objectFit}
    />
  );
};

export default Image;
