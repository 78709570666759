import useCarouselRef from "hooks/useCarouselRef";
import { formatTime } from "lib/utils";
import Translate, { useLanguageContext } from "locale/Translate";
import Link from "next/link";
import Image from "components/Shared/image";

export const SliderName = ({ name }) => (
  <div>
    <div className="fh5co_heading fh5co_heading_border_bottom py-2 mb-4">
      {name || <Translate id="trending-title" />}
    </div>
  </div>
);

export const SliderItem = ({ article }) => {
  const { locale } = useLanguageContext();
  return (
    <Link href={"/" + article.slug}>
      <div className="item">
        <div className="fh5co_latest_trading_img_position_relative">
          <div
            className="fh5co_latest_trading_img"
            // style={{
            //   background: `url(${getStrapiMedia(article.image)})`,
            //   minWidth: "100%",
            //   // height: "550px",
            //   backgroundSize: "cover",
            //   backgroundPosition: "center",
            //   backgroundRepeat: "no-repeat",
            // }}
          >
            <Image
              image={article.image}
              className="fh5co_img_special_relative"
            />
          </div>
          <div className="fh5co_latest_trading_img_position_absolute"></div>
          <div className="fh5co_latest_trading_img_position_absolute_1">
            <p className="text-white"> {article.title} </p>
            <div className="fh5co_latest_trading_date_and_name_color">
              {formatTime(article.created_at || article.published_at, locale)}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export const Category4Items = ({ name, articles }) => {
  const ref = useCarouselRef({
    loop: false,
    margin: 10,
    dots: false,
    nav: true,
    navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  });

  return (
    <div className="container-fluid pt-3">
      <div
        className="container animate-box container-sm"
        data-animate-effect="fadeIn"
      >
        <SliderName name={name} />
        <div ref={ref} className="owl-carousel owl-theme js" id="sliderX">
          {articles.map((article) => (
            <SliderItem key={article.slug} article={article} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Category4Items;
